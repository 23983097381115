<template>
  <v-main
    class="fill-height ml-4 mr-4 mt-4 mb-4"
    fluid
  >
 <v-card height="700px">
        <v-layout align-center justify-center column fill-height>
            <v-flex row align-center>
        <h1>  {{job_data.action}} </h1>
                <v-progress-circular
                    v-if=loading
                    indeterminate
                    :color="color"
                    size="200"
                    :value=job_data.message
                >
                    <div>{{ job_data.message }}</div>

                </v-progress-circular>    
                </v-flex>
        </v-layout>
     </v-card>
       

</v-main>
</template>

<script>

export default {
 
  data: () => ({
       job_uuid: '',
       job_data: {},
       loading: true,
       intervalId: "",
       color: "primary"
   
  }),
  created () {
    var self = this
    this.job_uuid = this.$route.params.job_uuid
    this.$store.dispatch('getJobStatus' , {job_uuid:this.job_uuid})
     .then(response => {

                this.job_data = response.data.data
                if(this.job_data.status == "pending"){
                    this.loading = true
                }

              })
     .catch(err => {        
                    this.snackbarMessage = err.response.data;
                    this.snackbar = true

              })


     this.intervalId = setInterval( async function(){ 
        self.updateStatus()
         
    },10*1000)        

  },
  methods: {
       updateStatus (){
             this.$store.dispatch('updateJobStatus' , {job_uuid:this.job_uuid})
              .then(() => {

                    this.$store.dispatch('getJobStatus' , {job_uuid:this.job_uuid})
                    .then(response => {

                                this.job_data = response.data.data
                                if(this.job_data.status == "pending"){
                                     this.loading = true
                                 }else if(this.job_data.status == "successful" || this.job_data.status == "failed"){
                                     this.color = "green"
                                     this.job_data.message = this.job_data.status
                                     clearInterval(this.intervalId)
                                 }
                    })
                    .catch(err => {        
                                    this.snackbarMessage = err.response.data;
                                    this.snackbar = true

                            })

              })
                .catch(err => {        
                    this.snackbarMessage = err.response.data;
                    this.snackbar = true

              })
      }
  },
  destroyed (){
     clearInterval(this.intervalId)
  }

}
</script>
